<template>
    <div class="grid">
        <div class="col-12">
            <div class="card main-card">

                <Toast/>
                <DataTable
                    ref="dt"
                    :value="data"
                    dataKey="id"
                    :paginator="true"
                    :rows="25"
                    :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    sortField="metric"
                    :sortOrder=-1
                    :loading="loading1"
                    responsiveLayout="scroll"
                >
                    <template #empty>
                        No objectives yet.
                    </template>

                    <Column field="name" filterField="name" :header="User">
                      <template #body="slotProps">
                        <div
                          class="flex"
                          id="searchtabdatatable"
                          style="align-items: center"
                        >
                          <div
                            class="p-avatar-custom"
                            :style="{
                              backgroundImage:
                                'url(images/' +
                                slotProps.data.uid1 +
                                '.jpg), url(images/ludi_placeholder.png)',
                            }"
                          >
                          </div>
                        </div>
                      </template>
                    </Column>

                    <Column field="progress" header="Number of objectives" :sortable="false" >
                        <template #body="slotProps">
                            {{ slotProps.data.n }}
                        </template>
                    </Column>



                    <Column
                      headerStyle="min-width: 4rem; text-align: center"
                      bodyStyle="text-align: center; overflow: visible"
                    >
                        <template #body="slotProps">
                            <Button @click="view(slotProps.data.uid1)" icon="pi pi-check" label="View objectives" class="p-button-rounded p-button-secondary p-button-outlined p-button-sm mr-2 mb-2" />
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>

</template>

<script>
import UserService from '../service/UserService';

export default {
    data() {
        return {
            data: null,
            filters: {},
            loading1: true,
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading1 = true;
            this.userService.coachGetObjectives().then(data => {
                this.loading1 = false;
                this.data = data;
            });
        },
        view(id_) {
            this.$router.push({ name: 'objectivesuser', params: { uid: id_ } });
        }
    }
}
</script>

<style lang="scss">
@media (max-width: 450px) {
    .layout-wrapper .layout-main-container {
        padding-left: 0rem!important;
    }
    .layout-main-container {
        padding: 7rem 0rem 2rem 0rem!important;
    }
}
</style>
<style scoped lang="scss">
@media (max-width: 450px) {
    .grid {
        margin: 0px!important;
    }
    .main-card {
        padding: 0px!important;
    }
    .xlm_token {
        padding: 1rem;
    }
}
img {
    width: 32px;
    border-radius: 30px;
}
</style>
<style>
.p-avatar.p-avatar-image {
    overflow: hidden;
}
.p-avatar img {
    height: 40px!important;
    width: auto!important;
}
.p-avatar-custom {
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    /* border: 1px solid black; */

    background-size: cover;
    background-position: center;
}
.p-avatar-custom img {
    width: 100%;
}
.textarea {
    white-space: pre-wrap;
}
.p-rating {
    min-width: 110px;
}
.xlm_token {
    overflow: scroll;
}
</style>
